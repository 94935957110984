import React, {Component} from "react"
import {NavLink} from "react-router-dom"

import "./Footer.css"

class Footer extends Component {

    render() {

        return (
            <footer>

                <div className={"footer-inner"}>
                    {/* 第一列 */}
                    <div className={"footer-column"}>
                        <div className={"footer-heading"}>Find us.</div>
                        <div style={{lineHeight: '2em'}}>
                            <p style={{fontSize: '1.5em',fontWeight: 'bold'}}>中国China</p>
                            <p><strong>上海市徐汇区漕溪北路739号201室</strong></p>
                            <p>Room 201, No. 739 Caoxi North Road, Xuhui District, Shanghai</p>
                            <p><strong>上海市闵行区沪闵路7866号莲花国际广场1号楼907室</strong></p>
                            <p>Room 907, Building 1, Lianhua International Plaza, No.7866, Humin Road, Minhang District, Shanghai</p>
                            

                            {/* <p style={{fontSize: '1.5em',fontWeight: 'bold',marginTop:'10px'}}>新加坡Singapore</p>
                            <p>#08-13 33 Ubi Avenue 3, Vertex SINGAPORE 408868 SINGAPORE</p> */}

                            <p style={{marginTop:'10px'}}>Email：info@blackbox-x.tech</p>
                            <p>Wechat：Blackbox-R</p>
                        </div>
                    </div>
                    {/* 第二列 */}
                    <div className={"footer-column"}>
                        <div className={"footer-heading"}>Discover us.</div>
                        {/* <NavLink to="/us">Us</NavLink> */}
                        <NavLink to="/services">Services</NavLink>
                        <NavLink to="/interactive">Interactive</NavLink>
                        <NavLink to="/webDevelopment">Web Development</NavLink>
                    </div>

                    {/* 第三列 */}
                    <div className={"footer-column"}>
                        <div className={"footer-heading"}>Join us.</div>
                        <NavLink to="/team">Team</NavLink>
                        <NavLink to="/careers">Careers</NavLink>
                        <NavLink to="/contact">Contact</NavLink>
                    </div>

                    {/* 第四列 */}
                    <div className={"footer-column"}>
                        <div className={"footer-heading"}>Stalk us.</div>
                        {/* <a href="https://www.facebook.com/blackboxinteractive1/">Facebook</a> */}
                        <a href="https://instagram.com/blackbox_interactive/">Instagram</a>
                        {/* <img src="/resources/contact/wechatcode.png" alt="wechat" className="weChatPic"></img> */}
                    </div>

                </div>
                <div id={"copyright"}>
                    <p>&copy;2021-2024 黑洞盒 版权所有 上海黑洞盒信息科技有限公司 备案号<a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">沪ICP备2021033102号-1</a></p>
                </div>
            </footer>
        )
    }

}

export default Footer